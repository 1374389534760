import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order }) => {
  sortBy = sortBy === "name" ? "category" : sortBy;
  const response = await api.get(
    `/categories?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}`
  );
  return response.data;
};

const selectData = async () => {
  const response = await api.get(`/categories`);
  return response.data;
};

const createCategory = async (data) => {
  const { category, createdate, createdby, lastupdate, updatedby } = data;
  const response = await api.post(`/category`, {
    category,
    createdate,
    createdby,
    lastupdate,
    updatedby,
  });
  return response.data;
};

const updateCategory = async (data) => {
  const { category, lastupdate, updatedby, id } = data;

  const response = await api.put(`/category`, {
    category,
    lastupdate,
    updatedby,
    id,
  });
  return response.data;
};

const getCategory = async (id) => {
  const response = await api.get(`/category/${id}`);
  return response.data;
};

const deleteCategory = async (id) => {
  const response = await api.delete(`/category/${id}`);
  return response.data;
};

const apiMethods = {
  fetchData,
  selectData,
  createCategory,
  updateCategory,
  getCategory,
  deleteCategory,
};
export default apiMethods;
