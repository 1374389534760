import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order }) => {
    const response = await api.get(
        `/discounts?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}`
    );
    return response.data;
};

const apiMethods = { fetchData };
export default apiMethods;