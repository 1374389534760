import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Navbar,
  Nav,
  Accordion,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import authService from "../../services/authService";
import { useAuth } from "../../context/authContext";
import { getFirstName } from "../../config/utils";
import Loading from "../Loading";
import Logo from "../../assets/img/logo.png";

function Sidebar(props) {
  const { user } = useAuth();
  const [sidebar, setSidebar] = useState(true);
  const [loading] = useState(false);

  const toggleSidebar = () => {
    setSidebar((prevSidebar) => !prevSidebar);
  };

  const logout = async () => {
    try {
      await authService.logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <div className={sidebar ? "d-flex" : "d-flex toggled"} id="wrapper">
      <div id="sidebar-wrapper">
        <div className="sidebar-heading d-flex align-items-center justify-content-center flex-column">
          <Link to="/dashboard">
            {" "}
            <img src={Logo} alt="" className="img-fluid mb-2 rounded" />
          </Link>
        </div>
        <Accordion>
          {/* DASHBOARD */}
          <Card className="border-0">
            <Card.Header className="p-0 border-0">
              <NavItem
                icon={"dashboard"}
                name={"Dashboard"}
                link="/dashboard"
              />

              <NavItem
                icon={"people_alt"}
                name={"Assinaturas"}
                link="/subscriptions"
              />
              <NavItem
                icon={"notifications"}
                name={"Notificações"}
                link="/notifications"
              />
              <hr className="hr-sidebar" />
              <NavItem
                icon={"storefront"}
                name={"Parceiros"}
                link="/establishments"
              />
              <NavItem
                icon={"add_shopping_cart"}
                name={"Parceiros Utilizados"}
                link="/used-discounts"
              />
              <hr className="hr-sidebar" />
              <NavItem icon={"groups"} name={"Estabelecimentos"} link="/partners" />

              <NavItem icon={"label"} name={"Categorias"} link="/categories" />

              <NavItem icon={"percent"} name={"Experiências"} link="/coupons" />
            </Card.Header>
          </Card>
        </Accordion>
        <div className="sidebar-logout-container">
          <hr className="hr-sidebar" />
          <LogoutItem
            icon={"logout"}
            name={"Logout"}
            className={"c-pointer"}
            onClick={logout}
          />
        </div>
      </div>
      <div
        id="page-content-wrapper"
        className="d-flex justify-content-between flex-column"
      >
        <div className="d-flex flex-column w-100 h-100 overflow-auto justify-content-between">
          <div>
            <Navbar
              collapseOnSelect
              expand="lg"
              className="py-3 bg-color-light-gray justify-content-between"
            >
              <div className="ms-3 d-flex justify-content-center align-items-center">
                <span
                  className="material-icons c-pointer"
                  onClick={toggleSidebar}
                >
                  reorder
                </span>
                <h6 className="ms-3 mt-1 mb-0 lh-1">
                  <a
                    href={props.pageUrl}
                    className="text-decoration-none me-2 color-dark-gray fw-bold menu"
                  >
                    {props.pageName}
                  </a>
                </h6>
              </div>
              <Nav className="ms-auto me-3 me-md-0 p-0">
                <Nav.Link
                  className="p-0 d-flex d-sm-none"
                  href="#"
                  onClick={logout}
                >
                  Sair
                </Nav.Link>
                <DropdownButton
                  align={{ lg: "end" }}
                  title={
                    <>
                      {user && (
                        <span className="color-dark-gray me-3">
                          Olá, <strong>{getFirstName(user.fullName)}</strong>!
                        </span>
                      )}
                    </>
                  }
                  id="dropdown-menu-align-right"
                  className="p-0 d-none d-sm-flex"
                >
                  <Dropdown.Item eventKey="4" onClick={logout}>
                    Sair
                  </Dropdown.Item>
                </DropdownButton>
              </Nav>
            </Navbar>
            <div className="p-sm-4 px-md-0 py-md-2 background-white">
              {props.children}
            </div>
          </div>
          <div className="w-100 py-3 text-center small">
            <p className="color-dark-gray">
              © {new Date().getFullYear()} - Clube Cult. Criado por{" "}
              <a
                className="color-dark-gray font-semi-bold text-decoration-none"
                href="https://starchild.com.br"
                target="_blank"
                rel="noreferrer"
              >
                starchild tech
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Loading show={loading} />
    </div>
  );
}

function NavItem(props) {
  const location = useLocation();
  const isActive = location.pathname.includes(props.link);

  return (
    <div className="list-group list-group-flush">
      <Link
        className={`list-group-item list-group-item-action ${
          isActive ? `item-active` : ``
        }`}
        to={props.link}
      >
        <span className="d-flex align-items-center">
          <span className="material-icons">{props.icon}</span>
          <span className="name-list">{props.name}</span>
        </span>
      </Link>
    </div>
  );
}

function LogoutItem(props) {
  const { onClick } = props;

  return (
    <div className={`list-group list-group-flush ${props.className}`}>
      <div className="list-group-item list-group-item-action" onClick={onClick}>
        <span className="d-flex align-items-center">
          <span className="material-icons">{props.icon}</span>
          <span className="name-list">{props.name}</span>
        </span>
      </div>
    </div>
  );
}

export default Sidebar;
