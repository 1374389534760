import React, {useCallback, useEffect, useState} from "react";
import Swal from "sweetalert2";
import Sidebar from "../../../components/Sidebar";
import {Button, Col, Container, Row} from "react-bootstrap";
import FilterBar from "../../../components/FiltersBar";
import {
    CardList,
    CardListBody, CardListBodyItem, CardListBodyItemGroupButton,
    CardListHeader,
    CardListHeaderItem,
    CardListHeaderSortItem
} from "../../../components/CardList";
import {limitString} from "../../../config/utils";
import {Link} from "react-router-dom";
import Loading from "../../../components/Loading";
import {ButtonFloating} from "../../../components/Buttons";
import categoriesService from "../../../services/categoriesService";

function Categories() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataInfo, setDataInfo] = useState({});
    const [filters, setFilters] = useState({
        page: 1,
        sort: { sortBy: "name", order: "desc" },
        limit: 20,
        searchName: "",
    });

    const filterConfig = [
        {
            type: "text",
            label: "Pesquisar pelo nome da categoria",
            name: "searchName",
            size: 3,
        },
        {
            type: "select",
            label: "Número de itens",
            name: "limit",
            options: [
                { label: "5", value: 5 },
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "30", value: 30 },
                { label: "40", value: 40 },
                { label: "50", value: 50 },
                { label: "100", value: 100 },
            ],
            size: 2,
        },
    ];

    const prevPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
        }));
    };

    const nextPage = () => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page:
                prevFilters.page < dataInfo.totalPages
                    ? prevFilters.page + 1
                    : prevFilters.page,
        }));
    };

    const handleInputSearch = (event) => {
        const { name, value } = event.target;
        setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
    };

    const refreshItems = async () => {
        setLoading(false);
    };

    const fetchCategories = useCallback(async () => {
        setLoading(true);

        try {
            const { page, limit, sort, searchName } = filters;
            const params = {
                page,
                limit,
                sortBy: sort.sortBy,
                order: sort.order,
                name: searchName,
            };

            const response = await categoriesService.fetchData(params);
            console.log(response);
            setData(response.data);
            setDataInfo({ ...response });
        } catch (error) {
            Swal.fire("Erro!", error.message, "error");
        } finally {
            refreshItems();
        }
    }, [filters]);

    useEffect(() => {
        document.title = "Clube Cult - Web APP - Categorias";
        fetchCategories();
    }, [fetchCategories]);

    const handleSort = (sortKey) => {
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                sort: {
                    sortBy: sortKey,
                    order:
                        prevFilters.sort.sortBy === sortKey &&
                        prevFilters.sort.order === "asc"
                            ? "desc"
                            : "asc",
                },
            };
            return newFilters;
        });
    };

    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
            title: "Deletar categoria",
            text: `Tem certeza que deseja deletar a categoria "${name}"?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Sim, deletar",
        });

        if (result.isConfirmed) {
            try {
                Swal.fire({
                    title: 'Deletando...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                await categoriesService.deleteCategory(id);

                Swal.close();

                await Swal.fire({
                    title: "Deletado!",
                    text: "A categoria foi deletado com sucesso",
                    icon: "success"
                });

                handleSort("name");
            } catch (error) {
                console.log(error);
                Swal.fire({
                    title: "Erro!",
                    text: "Erro desconhecido. Não foi possível realizar a ação",
                    icon: "error"
                });
            }
        }
    }

    return (
        <>
            <Sidebar pageName="Categorias" pageUrl="/categories">
                <Container fluid>
                    <FilterBar
                        config={filterConfig}
                        onFilterChange={handleInputSearch}
                        filters={filters}
                    />
                    <Row>
                        <Col>
                            <CardList
                                page={parseInt(dataInfo.page)}
                                data={data}
                                pages={dataInfo.totalPages}
                                callbackNext={nextPage}
                                callbackPrev={prevPage}
                            >
                                <CardListHeader className="bg-color-light-gray">
                                    <Row>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={4}
                                            onSort={handleSort}
                                            sortKey={"category"}
                                        >
                                            Nome
                                        </CardListHeaderSortItem>
                                        <CardListHeaderSortItem
                                            xs={12}
                                            lg={3}
                                            onSort={handleSort}
                                            sortKey={"createdby"}
                                        >
                                            Criado por:
                                        </CardListHeaderSortItem>
                                        <CardListHeaderItem xs={12} lg={2} className="text-center">
                                            Gerenciar
                                        </CardListHeaderItem>
                                    </Row>
                                </CardListHeader>
                                {data.map((data, index) => (
                                    <CardListBody key={index}>
                                        <Row>
                                            <CardListBodyItem
                                                xs={12}
                                                lg={4}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Nome:"}
                                                value={limitString(data.category, 40)}
                                            />
                                            <CardListBodyItem
                                                xs={12}
                                                lg={4}
                                                className="d-inline-flex align-items-center text-muted small"
                                                title={"Criado por:"}
                                                value={data.createdby}
                                            />
                                            <CardListBodyItemGroupButton
                                                xs={12}
                                                lg={2}
                                                className="d-inline-flex align-items-center justify-content-between"
                                            >
                                                <Button as={Link} to={`/categories/edit/${data.id}`}>Editar</Button>
                                                <Button variant="danger" onClick={() => handleDelete(data.id, data.category)}>Excluir</Button>
                                            </CardListBodyItemGroupButton>
                                        </Row>
                                    </CardListBody>
                                ))}
                                {data.length === 0 ? (
                                    <>
                                        <CardListBody>
                                            <Col
                                                xs={12}
                                                className="d-inline-flex align-items-center text-muted small justify-content-center"
                                            >
                                                Nenhum item encontrado.
                                            </Col>
                                        </CardListBody>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </CardList>
                        </Col>
                    </Row>
                </Container>
            </Sidebar>
            <Loading show={loading} />
            <ButtonFloating
                icon="add"
                style={{ borderRadius: '50%', width: '60px', height: '60px' }}
                btnColor="primary"
                link="/categories/create"
            />
        </>
    );
}

export default Categories;