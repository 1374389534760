import { Container } from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import EditPartner from "../../../../components/Forms/EditPartner";
import partnersService from "../../../../services/partnersService";

function EditPartnerScreen() {
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [partnerData, setPartnerData] = useState([]);
    const { id } = useParams();

    const fetchPartner = useCallback(async () => {
        setLoading(true);
        setLoadingData(true);
        try {
            const response = await partnersService.getPartner(id);

            setPartnerData(response);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os dados:", error);
        } finally {
            setLoading(false);
            setLoadingData(false);
        }
    }, [id]);

    useEffect(() => {
        document.title = "Clube Cult - Web APP - Editar Parceiro";
        fetchPartner();
    }, [fetchPartner]);

    return (
        <>
            <Sidebar
                pageName="Editar Parceiro"
                pageUrl={`/partners/edit/${id}`}
            >
                <Container fluid>
                    {!loadingData && partnerData && (
                        <EditPartner
                            setLoading={setLoading}
                            data={partnerData}
                            id={id}
                        />
                    )}
                </Container>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default EditPartnerScreen;