import Form from "react-bootstrap/Form";

function Select({ name, options, onChange, required, selectedValue }) {
  return (
    <Form.Select 
    aria-label="Select" 
    onChange={onChange}
    required={required}
    name={name}
    className="form-control"
    value={selectedValue}
    >
      <option value="">Selecione uma opção...</option>
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
}

export default Select;
