import api from "../config/api.js";

const fetchData = async ({ page, limit }) => {
  const response = await api.get(
    `/used-discounts?page=${page}&limit=${limit}`
  );
  return response.data;
};

const apiMethods = { fetchData };
export default apiMethods;
