import React from "react";
import { Row, Col, Button } from "react-bootstrap";

const FilterBar = ({ config, filters, onFilterChange, onAddClick, onAddText }) => {
    return (
        <Row className="m-2 justify-content-end">
            {config.map((item, index) => (
                <Col key={index} xs={12} sm={item.size} className="text-right">
                    {item.label && <small>{item.label}</small>}
                    {item.type === "text" && (
                        <input
                            type="text"
                            className="form-control input-search"
                            name={item.name}
                            value={filters[item.name]}
                            onChange={onFilterChange}
                        />
                    )}
                    {item.type === "select" && (
                        <select
                            className="form-control input-search"
                            name={item.name}
                            value={filters[item.name]}
                            onChange={onFilterChange}
                        >
                            {item.options.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}
                    {item.type === "date" && (
                        <input
                            type="date"
                            className="form-control input-search"
                            name={item.name}
                            value={filters[item.name]}
                            onChange={onFilterChange}
                        />
                    )}
                </Col>
            ))}
            {onAddClick && (
                <Col
                    xs={12}
                    sm={2}
                    className="d-flex align-items-end justify-content-end"
                >
                    <Button
                        className="custom-btn btn-app btt-add"
                        onClick={onAddClick}
                    >
                        {onAddText || "Adicionar"}
                    </Button>
                </Col>
            )}
        </Row>
    );
};

export default FilterBar;
