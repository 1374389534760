import React, { useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import CreateEstablishment from "../../../../components/Forms/CreateEstablishment";

function Create() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Sidebar pageName="Criar Estabelecimento" pageUrl="/establishments">
        <Container fluid>
          <CreateEstablishment setLoading={setLoading} />
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Create;
