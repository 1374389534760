import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import couponService from "../../../../services/couponService";
import EditCoupon from "../../../../components/Forms/EditCoupon";

function EditCouponScreen() {
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [couponData, setCouponData] = useState([]);
    const { id } = useParams();

    const fetchNotification = useCallback(async () => {
        setLoading(true);
        setLoadingData(true);
        try {
            const response = await couponService.getCoupon(id);

            setCouponData(response);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os dados:", error);
        } finally {
            setLoading(false);
            setLoadingData(false);
        }
    }, [id]);

    useEffect(() => {
        document.title = "Clube Cult - Web APP - Editar Desconto";
        fetchNotification();
    }, [fetchNotification]);

    return (
        <>
            <Sidebar
                pageName="Editar Desconto"
                pageUrl={`/coupons/edit/${id}`}
            >
                <Container fluid>
                    {!loadingData && couponData && (
                        <EditCoupon
                            setLoading={setLoading}
                            data={couponData}
                            id={id}
                        />
                    )}
                </Container>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default EditCouponScreen;