import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import EditEstablishment from "../../../../components/Forms/EditEstablishment";
import establishmentService from "../../../../services/establishmentService";
import { useParams } from "react-router-dom";

function Edit() {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [establishmentData, setEstablishmentData] = useState([]);
  const { id } = useParams();

  const fetchEstablishment = useCallback(async () => {
    setLoading(true);
    setLoadingData(true);
    try {
      const response = await establishmentService.getEstablishment(id);

      setEstablishmentData(response);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setLoading(false);
      setLoadingData(false);
    }
  }, [id]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Editar Estabelecimento";
    fetchEstablishment();
  }, [fetchEstablishment]);

  return (
    <>
      <Sidebar
        pageName="Editar Estabelecimento"
        pageUrl={`/establishments/edit/${id}`}
      >
        <Container fluid>
          {!loadingData && establishmentData && (
            <EditEstablishment
              setLoading={setLoading}
              data={establishmentData}
              id={id}
            />
          )}
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Edit;
