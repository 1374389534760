import { useEffect, useState, useCallback } from "react";
import Sidebar from "./../../../components/Sidebar/index";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ButtonFloating } from "../../../components/Buttons";
import { limitString, dateHourFormat } from "../../../config/utils";
import { Link } from "react-router-dom";
import FilterBar from "../../../components/FiltersBar";
import notificationsBodyService from "../../../services/notificationsBodyService";
import Swal from "sweetalert2";
import Loading from "../../../components/Loading";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListHeaderSortItem,
  CardListBody,
  CardListBodyItem,
  CardListBodyItemGroupButton,
} from "../../../components/CardList";

function Notifications() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    sort: { sortBy: "title", order: "desc" },
    limit: 20,
    searchName: "",
  });

  const filterConfig = [
    {
        type: "text",
        label: "Pesquisar pelo título da notificação",
        name: "searchName",
        size: 3,
      },
    {
      type: "select",
      label: "Número de itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 2,
    },
  ];

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const refreshItems = async () => {
    setLoading(false);
  };

  const handleInputSearch = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSort = (sortKey) => {
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        sort: {
          sortBy: sortKey,
          order:
            prevFilters.sort.sortBy === sortKey &&
            prevFilters.sort.order === "asc"
              ? "desc"
              : "asc",
        },
      };
      return newFilters;
    });
  };


  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    try {
      const { page, limit, sort, searchName } = filters;
      const params = {
        page,
        limit,
        sortBy: sort.sortBy,
        order: sort.order,
        name: searchName,
      };
      const response = await notificationsBodyService.fetchData(params);
      setData(response.data);
      setDataInfo({ ...response });
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Notificações";
    fetchNotifications();
  }, [fetchNotifications]);

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: "Desativar notificação",
      text: `Tem certeza que deseja desativar a notificação "${name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Sim, desativar",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: 'Desativando...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        await notificationsBodyService.deleteNotification(id);

        Swal.close();
    
        await Swal.fire({
          title: "Desativada!",
          text: "A notificação foi desativada com sucesso",
          icon: "success"
        });

        fetchNotifications();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Erro!",
          text: "Erro desconhecido. Não foi possível realizar a ação",
          icon: "error"
        });
      }
    }
  }

  const handleActive = async (id, name) => {
    const result = await Swal.fire({
      title: "Ativar notificação",
      text: `Tem certeza que deseja ativar a notificação "${name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5c40",
      confirmButtonText: "Sim",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: 'Ativando...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        await notificationsBodyService.activeNotification(id);

        Swal.close();
    
        await Swal.fire({
          title: "Ativada!",
          text: "A notificação foi ativada com sucesso",
          icon: "success"
        });

        fetchNotifications();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Erro!",
          text: "Erro desconhecido. Não foi possível realizar a ação",
          icon: "error"
        });
      }
    }
  }

  const handleSendNotification = async (id, name) => {
    const result = await Swal.fire({
      title: "Enviar notificação",
      text: `Tem certeza que deseja enviar a notificação "${name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff5c40",
      confirmButtonText: "Sim",
    });

    if (result.isConfirmed) {
      try {
        Swal.fire({
          title: 'Enviando...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        await notificationsBodyService.sendNotification(id);

        Swal.close();
    
        await Swal.fire({
          title: "Enviada!",
          text: "A notificação foi enviada aos usuários",
          icon: "success"
        });

        fetchNotifications();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Erro!",
          text: "Erro desconhecido. Não foi possível realizar a ação",
          icon: "error"
        });
      }
    }
  }

  return (
    <>
      <Sidebar pageName="Notificações" pageUrl="/notifications">
        <Container fluid>
        <FilterBar
            config={filterConfig}
            onFilterChange={handleInputSearch}
            filters={filters}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                  <CardListHeaderSortItem
                      xs={12}
                      lg={4}
                      onSort={handleSort}
                      sortKey={"title"}
                    >
                      Título
                    </CardListHeaderSortItem>
                    <CardListHeaderItem xs={12} lg={2} className="text-center">
                      Descrição
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3} className="text-center">
                      Último Envio
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={3} className="text-center">
                      Gerenciar
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>
                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Título:"}
                        value={limitString(data.title, 40)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center justify-content-center text-muted small"
                        title={"Descrição:"}
                        value={limitString(data.description, 40)}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={3}
                        className="d-inline-flex align-items-center justify-content-center text-muted small"
                        title={"Último Envio:"}
                        value={dateHourFormat(
                            data.lastDispatch
                        )}
                      />
                      <CardListBodyItemGroupButton
                        xs={12}
                        lg={3}
                        className={`d-inline-flex align-items-center ${data.status === "active" ? "justify-content-between" : "justify-content-center"}`}
                      >
                        {data.status === "active" ? (
                            <>
                                <Button
                                as={Link}
                                to={`/notifications/edit/${data.id}`}
                                variant="outline-primary"
                                >
                                Editar
                                </Button>
                                <Button variant="outline-danger" onClick={() => handleDelete(data.id, data.title)}>Desativar</Button>
                                <Button variant="warning" onClick={() => handleSendNotification(data.id, data.title)}>Disparar</Button>
                            </>
                        ) : (<><Button variant="primary" onClick={() => handleActive(data.id, data.title)}>Ativar</Button></>)}
                        
                      </CardListBodyItemGroupButton>
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
      <ButtonFloating
        icon="add"
        style={{ borderRadius: '50%', width: '60px', height: '60px' }}
        btnColor="primary"
        link="/notifications/create"
      />
    </>
  );
}

export default Notifications;
