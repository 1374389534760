import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";
import Loading from "../../../components/Loading";
import usedDiscountsService from "../../../services/usedDiscountsService";
import Swal from "sweetalert2";
import FilterBar from "../../../components/FiltersBar";
import {
  CardList,
  CardListHeader,
  CardListHeaderItem,
  CardListBody,
  CardListBodyItem,
} from "../../../components/CardList";
import { dateHourFormat } from "../../../config/utils";

function Establishments() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 20,
  });

  const filterConfig = [
    {
      type: "select",
      label: "Número de itens",
      name: "limit",
      options: [
        { label: "5", value: 5 },
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "40", value: 40 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      size: 2,
    },
  ];

  const prevPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: prevFilters.page > 1 ? prevFilters.page - 1 : prevFilters.page,
    }));
  };

  const nextPage = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page:
        prevFilters.page < dataInfo.totalPages
          ? prevFilters.page + 1
          : prevFilters.page,
    }));
  };

  const refreshItems = async () => {
    setLoading(false);
  };

  const fetchEstablishments = useCallback(async () => {
    setLoading(true);
    try {
      const { page, limit } = filters;
      const params = {
        page,
        limit,
      };
      const response = await usedDiscountsService.fetchData(params);
      setData(response.data);
      setDataInfo({ ...response });
    } catch (error) {
      Swal.fire("Erro!", error.message, "error");
    } finally {
      refreshItems();
    }
  }, [filters]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Parceiros Utilizados";
    fetchEstablishments();
  }, [fetchEstablishments]);

  return (
    <>
      <Sidebar pageName="Parceiros Utilizados" pageUrl="/used-discounts">
        <Container fluid>
          <FilterBar
            config={filterConfig}
            filters={filters}
            onFilterChange={() => {
              return;
            }}
          />
          <Row>
            <Col>
              <CardList
                page={parseInt(dataInfo.page)}
                data={data}
                pages={dataInfo.totalPages}
                callbackNext={nextPage}
                callbackPrev={prevPage}
              >
                <CardListHeader className="bg-color-light-gray">
                  <Row>
                    <CardListHeaderItem xs={12} lg={4}>
                      Usuário
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={4}>
                      Parceiro
                    </CardListHeaderItem>
                    <CardListHeaderItem xs={12} lg={4}>
                      Quando foi usado?
                    </CardListHeaderItem>
                  </Row>
                </CardListHeader>
                {data.map((data, index) => (
                  <CardListBody key={index}>
                    <Row>
                      <CardListBodyItem
                        xs={12}
                        lg={4}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Usuário:"}
                        value={data.user?.name}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={4}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Parceiro:"}
                        value={data.establishment?.establishmentName}
                      />
                      <CardListBodyItem
                        xs={12}
                        lg={4}
                        className="d-inline-flex align-items-center text-muted small"
                        title={"Quando foi usado?"}
                        value={dateHourFormat(data.createDate)}
                      />
                    </Row>
                  </CardListBody>
                ))}
                {data.length === 0 ? (
                  <>
                    <CardListBody>
                      <Col
                        xs={12}
                        className="d-inline-flex align-items-center text-muted small justify-content-center"
                      >
                        Nenhum item encontrado.
                      </Col>
                    </CardListBody>
                  </>
                ) : (
                  <></>
                )}
              </CardList>
            </Col>
          </Row>
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default Establishments;
