import React, { useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import CreateCoupon from "../../../../components/Forms/CreateCoupon";


function CreateCouponScreen() {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Sidebar pageName="Criar Desconto" pageUrl="/coupons/create">
                <Container fluid>
                    <CreateCoupon setLoading={setLoading} />
                </Container>
            </Sidebar>
            <Loading show={loading} />
        </>
    );
}

export default CreateCouponScreen;