import {useState} from "react";
import Sidebar from "../../../../components/Sidebar";
import {Container} from "react-bootstrap";
import CreatePartner from "../../../../components/Forms/CreatePartners";

function CreatePartnerScreen() {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Sidebar pageName="Criar Parceiro" pageUrl="/notifications/create">
                <Container fluid>
                    <CreatePartner loading={loading} setLoading={setLoading} />
                </Container>
            </Sidebar>
        </>
    );
}

export default CreatePartnerScreen;