import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./config/privateRoutes";

// Pages
import Login from "./pages/Login";
import PasswordReset from "./pages/PasswordReset";

// Dashboard
import Dashboard from "./pages/Dashboard";
import Subscriptions from "./pages/Dashboard/Subscriptions";
import Establishments from "./pages/Dashboard/Establishments";
import Create from "./pages/Dashboard/Establishments/Create";
import Edit from "./pages/Dashboard/Establishments/Edit";
import UsedDiscounts from "./pages/Dashboard/UsedDiscounts";
import Notifications from './pages/Dashboard/Notifications';
import CreateNotificationScreen from './pages/Dashboard/Notifications/Create';
import EditNotificationScreen from './pages/Dashboard/Notifications/Edit';
import Partners from "./pages/Dashboard/Partners";
import CreatePartnerScreen from "./pages/Dashboard/Partners/Create";
import EditPartnerScreen from "./pages/Dashboard/Partners/Edit";
import Categories from "./pages/Dashboard/Categories";
import CreateCategoryScreen from "./pages/Dashboard/Categories/Create";
import EditCategoryScreen from "./pages/Dashboard/Categories/Edit";
import CreateCouponScreen from "./pages/Dashboard/Coupons/Create";
import Coupons from "./pages/Dashboard/Coupons";
import EditCouponScreen from "./pages/Dashboard/Coupons/Edit";

function RedirectToLogin() {
  return <Navigate to="/login" />;
}

const RootElement = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/alteracao-senha/:hash" element={<PasswordReset />} />
    {/* AUTENTICADO */}
    <Route
      path="/dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    {/* ADMIN */}
    <Route
      path="/subscriptions"
      element={
        <PrivateRoute adminOnly={true}>
          <Subscriptions />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments"
      element={
        <PrivateRoute adminOnly={true}>
          <Establishments />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments/create"
      element={
        <PrivateRoute adminOnly={true}>
          <Create />
        </PrivateRoute>
      }
    />
    <Route
      path="/establishments/edit/:id"
      element={
        <PrivateRoute adminOnly={true}>
          <Edit />
        </PrivateRoute>
      }
    />

    <Route
      path="/used-discounts"
      element={
        <PrivateRoute adminOnly={true}>
          <UsedDiscounts />
        </PrivateRoute>
      }
    />

    <Route
      path="/notifications"
      element={
        <PrivateRoute adminOnly={true}>
          <Notifications />
        </PrivateRoute>
      }
    />

    <Route
      path="/notifications/create"
      element={
        <PrivateRoute adminOnly={true}>
          <CreateNotificationScreen />
        </PrivateRoute>
      }
    />

    <Route
      path="/notifications/edit/:id"
      element={
        <PrivateRoute adminOnly={true}>
          <EditNotificationScreen />
        </PrivateRoute>
      }
    />
    <Route
      path="/partners"
      element={
          <PrivateRoute adminOnly={true}>
              <Partners />
          </PrivateRoute>
      }
    />
    <Route
      path="/partners/create"
      element={
          <PrivateRoute adminOnly={true}>
              <CreatePartnerScreen />
          </PrivateRoute>
      }
    />
    <Route
      path="/partners/edit/:id"
      element={
          <PrivateRoute adminOnly={true}>
              <EditPartnerScreen />
          </PrivateRoute>
      }
    />
    <Route
      path="/categories"
      element={
          <PrivateRoute adminOnly={true}>
              <Categories />
          </PrivateRoute>
      }
    />
    <Route
      path="/categories/create"
      element={
          <PrivateRoute adminOnly={true}>
              <CreateCategoryScreen />
          </PrivateRoute>
      }
    />
    <Route
      path="/categories/edit/:id"
      element={
          <PrivateRoute adminOnly={true}>
              <EditCategoryScreen />
          </PrivateRoute>
      }
    />
    <Route
      path="/coupons"
      element={
          <PrivateRoute adminOnly={true}>
              <Coupons />
          </PrivateRoute>
      }
    />
    <Route
      path="/coupons/create"
      element={
          <PrivateRoute adminOnly={true}>
              <CreateCouponScreen />
          </PrivateRoute>
      }
    />
    <Route
      path="/coupons/edit/:id"
      element={
          <PrivateRoute adminOnly={true}>
              <EditCouponScreen />
          </PrivateRoute>
      }
    />
    {/* 404 */}
    <Route path="*" element={<RedirectToLogin />} />
  </Routes>
);

export default RootElement;
