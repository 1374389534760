import { useState } from 'react';
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import CreateNotification from './../../../../components/Forms/CreateNotification/index';

function CreateNotificationScreen () {
    const [loading, setLoading] = useState(false);

    return (
      <>
        <Sidebar pageName="Criar Notificação" pageUrl="/notifications/create">
          <Container fluid>
            <CreateNotification setLoading={setLoading} />
          </Container>
        </Sidebar>
        <Loading show={loading} />
      </>
    );
}

export default CreateNotificationScreen;