import {Card, Col, Form, Row} from "react-bootstrap";
import {ButtonPrimary} from "../../Buttons";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/authContext";
import Swal from "sweetalert2";
import categoriesService from "../../../services/categoriesService";

function EditCategory({ setLoading, data, id }) {
    const navigate = useNavigate();

    const { user } = useAuth();
    const [category, setCategory] = useState({
        category: data.category,
        lastupdate: data.lastupdate,
        updatedby: user.email,
        id: id,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCategory((prevcategory) => ({
            ...prevcategory,
            [name]: value,
        }));
    };

    const submitData = async (event) => {
        event.preventDefault();
        setLoading(true);

        setCategory((prevcategory)=> ({
            ...prevcategory,
            lastupdate: new Date(),
        }));

        try {
            await categoriesService.updateCategory(category);

            setLoading(false);

            Swal.fire({
                title: "Categoria atualizado",
                text: "Os dados do parceiro foram atualizados com sucesso",
                icon: "success",
            }).then(() => {
                navigate("/categories");
            });
        } catch (error) {
            setLoading(false);
            Swal.fire(
                "Ocorreu um erro",
                "Houve um erro ao atualizar a categoria, tente novamente",
                "error"
            );
        }
    };

    return (
        <>
            <Card className="rounded-0 card-form m-75">
                <Row>
                    <Col xs={12} md={12} className="padding-card">
                        <h2 className="text-uppercase font-semi-bold mb-0">
                            Informações da Categoria
                        </h2>
                        <p className="font-light color-gray mb-4">
                            Coloque as informações da sua categoria abaixo.
                        </p>
                        <Form onSubmit={submitData}>
                            <Row className="mb-3">
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Categoria
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="category"
                                            placeholder="Digite o nome do estabelecimento"
                                            onChange={handleInputChange}
                                            value={category.category || ""}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary type="submit" btnText="SALVAR" />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default EditCategory;