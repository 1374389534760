import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order, createDate, status }) => {
    const response = await api.get(
        `/subscriptions?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}&status=${status}&createDate=${createDate}`
    );
    return response.data;
};

const deleteSubscription = async (id) => {
    const response = await api.delete(`/subscriptions/${id}`);
    return response.data;
};

const apiMethods = { fetchData, deleteSubscription };
export default apiMethods;
