import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order }) => {
    const response = await api.get(
        `/notifications?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}`
    );
    return response.data;
};

const createNotification = async (data) => {
    const { 
        title, 
        description, 
        image,
        createdBy,
        updatedBy,
        url,
        type
    } = data;
    const response = await api.post(`/notification`, {
        title, 
        description, 
        image,
        createdBy,
        updatedBy,
        url,
        type
    });
    return response.data;
};

const deleteNotification = async (id) => {
    const response = await api.delete(`/notification/${id}`);
    return response.data;
}

const activeNotification = async (id) => {
    const response = await api.put(`/notification/active`, { id });
    return response.data;
}

const sendNotification = async (id) => {
    const response = await api.post(`/notification/send`, { id });
    return response.data;
}

const getNotification = async (id) => {
    const response = await api.get(`/notification/${id}`);
    return response.data;
}

const updateNotification = async (data) => {
    const { 
        title, 
        description, 
        updatedBy,
        image,
        id,
        type,
        url
    } = data;


    const response = await api.put(`/notification`, {
        title, 
        description, 
        updatedBy,
        image,
        id,
        type,
        url
    });
    return response.data;
}

const apiMethods = { fetchData, createNotification, deleteNotification, activeNotification, sendNotification, getNotification, updateNotification };
export default apiMethods;