import {Card, Col, Form, Row} from "react-bootstrap";
import {ButtonPrimary} from "../../Buttons";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/authContext";
import Swal from "sweetalert2";
import partnersService from "../../../services/partnersService";

function CreatePartner({ setLoading }) {
    const navigate = useNavigate();

    const { user } = useAuth();
    const [partner, setPartner] = useState({
        name: "",
        adrress: "",
        document: "",
        createdby: user.email,
        createdate: new Date(),
        lastupdate: new Date(),
        updatedby: user.email,
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPartner((prevpartner) => ({
            ...prevpartner,
            [name]: value,
        }));
    };

    const submitData = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await partnersService.createPartner(partner);

            setLoading(false);

            Swal.fire({
                title: "Parceiro criado",
                text: "O parceiro foi criado com sucesso",
                icon: "success",
            }).then(() => {
                navigate("/partners");
            });
        } catch (error) {
            setLoading(false);
            Swal.fire(
                "Ocorreu um erro",
                "Houve um erro ao criar o parceiro, tente novamente",
                "error"
            );
        }
    };

    return (
        <>
            <Card className="rounded-0 card-form m-75">
                <Row>
                    <Col xs={12} md={12} className="padding-card">
                        <h2 className="text-uppercase font-semi-bold mb-0">
                            Informações do PArceiro
                        </h2>
                        <p className="font-light color-gray mb-4">
                            Coloque as informações do seu parceiro abaixo.
                        </p>
                        <Form onSubmit={submitData}>
                            <Row className="mb-3">
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Nome
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            placeholder="Digite o nome do estabelecimento"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Endereço
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="address"
                                            placeholder="Digite a descrição do estabelecimento"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="mb-3">
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            Documento
                                            <sup className="ms-1 text-danger fw-bold">*</sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="document"
                                            placeholder="Digite o documento"
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary type="submit" btnText="SALVAR" />
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default CreatePartner;