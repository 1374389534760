import { useState } from "react";
import Sidebar from "../../../../components/Sidebar";
import { Container } from "react-bootstrap";
import CreateCategory from "../../../../components/Forms/CreateCategory";

function CreateCategoryScreen() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Sidebar pageName="Criar Categoria" pageUrl="/categories/create">
        <Container fluid>
          <CreateCategory loading={loading} setLoading={setLoading} />
        </Container>
      </Sidebar>
    </>
  );
}

export default CreateCategoryScreen;
