import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../../../../components/Sidebar";
import Loading from "../../../../components/Loading";
import { Container } from "react-bootstrap";
import notificationsBodyService from "../../../../services/notificationsBodyService";
import { useParams } from "react-router-dom";
import EditNotification from './../../../../components/Forms/EditNotification';

function EditNotificationScreen() {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const { id } = useParams();

  const fetchNotification = useCallback(async () => {
    setLoading(true);
    setLoadingData(true);
    try {
      const response = await notificationsBodyService.getNotification(id);

      setNotificationData(response);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    } finally {
      setLoading(false);
      setLoadingData(false);
    }
  }, [id]);

  useEffect(() => {
    document.title = "Clube Cult - Web APP - Editar Notificação";
    fetchNotification();
  }, [fetchNotification]);

  return (
    <>
      <Sidebar
        pageName="Editar Notificação"
        pageUrl={`/establishments/edit/${id}`}
      >
        <Container fluid>
          {!loadingData && notificationData && (
            <EditNotification
              setLoading={setLoading}
              data={notificationData}
              id={id}
            />
          )}
        </Container>
      </Sidebar>
      <Loading show={loading} />
    </>
  );
}

export default EditNotificationScreen;