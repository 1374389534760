import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order }) => {
    sortBy = sortBy === "name" ? "establishmentName" : sortBy;
    const response = await api.get(
        `/establishments?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}`
    );
    return response.data;
};

const createEstablishment = async (data) => {
    const { 
        establishmentName, 
        establishmentDescription, 
        establishmentPromotions,
        establishmentConditions,
        createDate,
        createdBy,
        establishmentImage,
        lastUpdate,
        updatedBy
    } = data;
    const response = await api.post(`/establishment/`, {
        establishmentName, 
        establishmentDescription, 
        establishmentPromotions,
        establishmentConditions,
        createDate,
        createdBy,
        establishmentImage,
        lastUpdate,
        updatedBy
    });
    return response.data;
};

const updateEstablishment = async (data) => {
    const { 
        establishmentName, 
        establishmentDescription, 
        establishmentPromotions,
        establishmentConditions,
        establishmentImage,
        lastUpdate,
        updatedBy,
        id
    } = data;
    const response = await api.put(`/establishment`, {
        establishmentName, 
        establishmentDescription, 
        establishmentPromotions,
        establishmentConditions,
        establishmentImage,
        lastUpdate,
        updatedBy,
        id
    });
    return response.data;
}

const deleteEstablishment = async (id) => {
    const response = await api.delete(`/establishment/${id}`);
    return response.data;
};

const getEstablishment = async (id) => {
    const response = await api.get(`/establishment/${id}`);
    return response.data;
}

const apiMethods = { fetchData, deleteEstablishment, createEstablishment, getEstablishment, updateEstablishment };
export default apiMethods;
