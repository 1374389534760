import api from "../config/api.js";

const fetchData = async ({page, limit, name, sortBy, order}) => {
    sortBy = sortBy === "name" ? "name" : sortBy;
    const response = await api.get(
        `/partners?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}`
    );
    return response.data;
};

const createPartner = async (data) => {
    const {
        name,
        address,
        document,
        createdate,
        createdby,
        lastupdate,
        updatedby,
    } = data;
    const response = await api.post(`/partner/`, {
        name,
        address,
        document,
        createdate,
        createdby,
        lastupdate,
        updatedby
    });
    return response.data;
};

const updatePartner = async (data) => {
    const {
        name,
        address,
        document,
        lastupdate,
        updatedby,
        id,
    } = data;

    const response = await api.put(`/partner`, {
        name,
        address,
        document,
        lastupdate,
        updatedby,
        id,
    });
    return response.data;
};

const getPartner = async (id) => {
    const response = await api.get(`/partner/${id}`);
    return response.data;
}

const deletePartner = async (id) => {
    const response = await api.delete(`/partner/${id}`);
    return response.data;
};

const apiMethods = {fetchData, createPartner, updatePartner, getPartner, deletePartner};
export default apiMethods;