import api from "../config/api.js";

const fetchData = async ({ page, limit, name, sortBy, order, category }) => {
  sortBy = sortBy === "name" ? "title" : sortBy;
  const response = await api.get(
    `/coupons?page=${page}&limit=${limit}&searchName=${name}&sortBy=${sortBy}&order=${order}&category=${category}`
  );
  return response.data;
};

const createCoupon = async (data) => {
  const { limitedByDate, validUntil, validFrom } = data;

  if (validUntil && limitedByDate) {
    const oneDay = 24 * 60 * 60 * 1000;

    const validUntilTimestamp =
      typeof validUntil === "number" && validUntil > 0
        ? validUntil
        : new Date(`${validUntil}T12:00:00Z`).getTime();

    const validFromTimestamp =
      typeof validFrom === "number" && validFrom > 0
        ? validFrom
        : new Date(
            validFrom ? `${validFrom}T12:00:00Z` : validUntilTimestamp - oneDay
          ).getTime();

    if (validFromTimestamp > validUntilTimestamp) {
      throw new Error("A data de início deve ser anterior à data de final");
    }

    data.validFrom = validFromTimestamp;
    data.validUntil = validUntilTimestamp;
  }

  const response = await api.post(`/coupon`, data);

  return response.data;
};

const updateCoupon = async (data) => {
  const { limitedByDate, validUntil, validFrom } = data;

  if (validUntil && limitedByDate) {
    const oneDay = 24 * 60 * 60 * 1000;

    const validUntilTimestamp =
      typeof validUntil === "number" && validUntil > 0
        ? validUntil
        : new Date(`${validUntil}T12:00:00Z`).getTime();

    const validFromTimestamp =
      typeof validFrom === "number" && validFrom > 0
        ? validFrom
        : new Date(
            validFrom ? `${validFrom}T12:00:00Z` : validUntilTimestamp - oneDay
          ).getTime();

    if (validFromTimestamp > validUntilTimestamp) {
      throw new Error("A data de início deve ser anterior à data de final");
    }

    data.validFrom = validFromTimestamp;
    data.validUntil = validUntilTimestamp;
  }

  const response = await api.put(`/coupon`, data);
  return response.data;
};

const getCoupon = async (id) => {
  const response = await api.get(`/coupon/${id}`);
  return response.data;
};

const deleteCoupon = async (id) => {
  const response = await api.delete(`/coupon/${id}`);
  return response.data;
};

const apiMethods = {
  fetchData,
  createCoupon,
  updateCoupon,
  getCoupon,
  deleteCoupon,
};
export default apiMethods;
